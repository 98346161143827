<template>
  <div>
    <h4>
      HISTORIAS CLINICAS
      <span hidden>{{ !!initiated ? "INICIADOS" : "NO INICIADOS" }}</span>
    </h4>
    <app-table-registers
      ref="tr"
      :getList="getList"
      @btnNew="
        $refs.dFormCreate.show();
        $refs.formCreate.reset();
        $refs.formCreate.setClientId(client_id);
      "
      :disableBtnNew="!config.edit"
      :disableSearch="true"
    >
      <SingleViewTr
        v-for="l in list.data"
        :key="l.id"
        class="mb-3"
        :reg="l"
        @changePlan="$refs.tr.gl()"
      ></SingleViewTr>
    </app-table-registers>

    <app-modal-basic ref="dFormCreate">
      <FormCreate
        ref="formCreate"
        :canSetClient="false"
        @submitted="$router.push($event.url)"
      ></FormCreate>
    </app-modal-basic>
  </div>
</template>

<script>
import { DentalClinicService } from "../service";
import FormCreate from "./FormCreate";
import SingleViewTr from "./SingleView";
import { mapState } from "vuex";

export default {
  components: {
    FormCreate,
    SingleViewTr
  },
  props: {
    client_id: {},
    initiated: {
      default: undefined
    }
  },
  computed: {
    ...mapState({
      config: s => s.config.service.treatments
    })
  },
  data: () => ({
    list: {}
  }),
  mounted() {
    this.$refs.formCreate.setClientId(this.client_id);
  },
  watch: {
    $route() {
      // this.$refs.tr.gl();
    }
  },
  methods: {
    getList(params) {
      return new Promise(rsv => {
        DentalClinicService.getTreatmentPlans({
          ...params,
          client_id: this.client_id,
          initiated: this.initiated
        }).then(res => {
          this.list = res;
          rsv(res);
        });
      });
    }
    // goToTreatment(e) {
    //   this.$router.push(
    //     "/dental-clinic/patients/" + this.client_id + "/treatment-plans/" + e
    //   );
    // }
  }
};
</script>

<style></style>
